    import Offcanvasx from "../componenets/Offcanvas";
    import Navbarx from "../componenets/Navbar";
    import VideoArea from "../componenets/VideoArea";
    import { useParams } from "react-router-dom";
    import { getUser, useUserActions } from "../hooks/user.actions";
    import { useState, useEffect } from "react";
    import Modal from "react-bootstrap/Modal";
    import Dropdown from "react-bootstrap/Dropdown";

    function UserProfile() {
    const { userId } = useParams();
    const userActions = useUserActions();

    const user = getUser();
    const [userData, setUserData] = useState([]);

    const [isFollowing, setIsFollowing] = useState(null);
    const [numFollowers, setNumFollowers] = useState(null);

    const [modalShow, setModalShow] = useState(false);
    const [passwordModalShow, setPasswordModalShow] = useState(false);

    const [updatePic, setUpdatePic] = useState("");
    const [disableFileInput, setDisableFileInput] = useState(false);

    const [errorModal, setErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const [errorModalTitle, setErrorModalTitle] = useState("");

    const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConf: "",
    });
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [oldPasswordError, setOldPasswordError] = useState(null);

    const handleErrorModalClose = () => {
    setErrorModal(false);
    setModalShow(false);
    };

    const handlePasswordModalClose = () => {
    setPasswordModalShow(false);
    setNewPasswordError("");
    setOldPasswordError("");
    };

    const handleModalClose = () => setModalShow(false);
    const handleModalProfilePicShow = () => {
    if (userId !== user?.id) return;
    setModalShow(true);
    setUpdatePic("Profile");
    };
    const handleModalBackgroundPicShow = () => {
    if (userId !== user?.id) return;
    setModalShow(true);
    setUpdatePic("Background");
    };

    useEffect(() => {
    fetchData();
    }, []);

    const fetchData = async () => {
    const getService = userActions.folloFromProfilewAxios();
    const response = await getService
        .get(process.env.REACT_APP_BASE_URL + `/user/details/${userId}`)
        .catch((err) => console.log("UserProfile, err: ", err));
    if (typeof response !== "undefined") {
        setIsFollowing(response.data.is_following);
        setNumFollowers(response.data.num_followers);
        setUserData(response.data);
    }
    };

    const handleOnFollowBtnClick = () => {
    const postService = userActions.folloFromProfilewAxios();
    if (!postService) {
        console.log("Must be logged in to follow");
        return;
    }
    postService
        .post(process.env.REACT_APP_BASE_URL + `/user/follow/${userId}/`)
        .then(() =>
        isFollowing
            ? setIsFollowing(false) & setNumFollowers((prevNum) => prevNum - 1)
            : setIsFollowing(true) & setNumFollowers((prevNum) => prevNum + 1)
        )
        // .then((res)=> (console.log(res.data)))
        .catch((err) => {
        console.log("UserProfile, handleOnFollowBtnClick, err: ", err);
        try {
            if (err.response?.data[0] === "Can not follow yourself") {
            console.log("Can not follow yourself");
            }
        } catch {}
        });
    };

    const handleProfileImageChange = (e) => {
    e.preventDefault();
    setDisableFileInput(true);

    userActions
        .edit({ profile_pic: e.target.files[0] }, userId)
        .then(() => {
        setModalShow(false);
        window.location.reload();
        setDisableFileInput(false);
        })
        .catch((err) => {
        if (err.response["data"] === "Email not verified") {
            setErrorModalTitle("Email not verified!");
            setErrorModalMessage("upload a profile picture");
            setErrorModal(true);
            setDisableFileInput(false);
        } else {
            setDisableFileInput(false);
            console.log(err);
        }
        });
    };

    const handleBackgroundImageChange = (e) => {
    e.preventDefault();
    setDisableFileInput(true);

    userActions
        .edit({ background_pic: e.target.files[0] }, userId)
        .then(() => {
        setModalShow(false);
        window.location.reload();
        setDisableFileInput(false);
        })
        .catch((err) => {
        if (err.response["data"] === "Email not verified") {
            setErrorModalTitle("Email not verified!");
            setErrorModalMessage("upload a backgournd picture");
            setErrorModal(true);
            setDisableFileInput(false);
        } else {
            setDisableFileInput(false);
            console.log(err);
        }
        });
    };

    const handleFormSubmit = (event) => {
    event.preventDefault();
    setNewPasswordError("");
    setOldPasswordError("");

    if (form.newPassword !== form.newPasswordConf) {
        setNewPasswordError(["The two passwords must be the same"]);
        return;
    }

    const axiosService = userActions.changeUserPassword();
    axiosService
        .patch(process.env.REACT_APP_BASE_URL + "/user/change_password/", {
        old_password: form.oldPassword,
        new_password: form.newPassword,
        })
        .then((res) => console.log(res))
        .catch((err) => {
        setNewPasswordError(err.response.data.new_password);
        setOldPasswordError(err.response.data.old_password);
        });
    };

    return (
    <>
        <Navbarx />
        <div
        className="container-fluid d-flex flex-column justify-content-start align-items-center"
        style={{
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 54,
            backgroundColor: "#fcf5e1",
        }}
        >
        <Offcanvasx />

        {/* ////////////////////////////////////////////// Backgournd pic */}
        <div
            id="profile-backgournd-row"
            className="row d-flex flex-grow-0 flex-shrink-1"
            style={{
            width: "100%",
            overflow: "hidden",
            background: "#422ebb",
            height: "auto",
            }}
        >
            <div
            className="col"
            style={{
                height: "100%",
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                overflow: "hidden",
            }}
            >
            <img
                id="background-image"
                className="img-fluid border-0"
                loading="lazy"
                style={{ margin: "43px 0px 0px", marginTop: 0, width: "100%" }}
                width={356}
                height={151}
                src={userData?.background_pic?.substring(
                0,
                userData.background_pic.lastIndexOf("?")
                )}
                alt={userData?.username}
            />

            {userId === user?.id && (
                <Dropdown
                drop={"down-centered"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    marginTop: 30,
                }}
                >
                <Dropdown.Toggle
                    className=" d-none d-sm-none d-md-none d-lg-inline"
                    style={{
                    background: "transparent",
                    marginTop: 45,
                    marginLeft: 3,
                    }}
                >
                    Profile settings
                </Dropdown.Toggle>
                <Dropdown.Menu
                    style={{
                    borderRadius: 0,
                    borderColor: "black",
                    borderWidth: "1px",
                    fontFamily: '"Hachi Maru Pop", serif',
                    background: "#fcf5e1",
                    padding: 0,
                    }}
                >
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={handleModalProfilePicShow}
                    >
                    Change profile pic
                    </Dropdown.Item>
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={handleModalBackgroundPicShow}
                    >
                    Change background pic
                    </Dropdown.Item>
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={setPasswordModalShow}
                    >
                    Change Password
                    </Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            )}

            {userId === user?.id && (
                <Dropdown
                drop={"down-centered"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    marginTop: 30,
                }}
                >
                <Dropdown.Toggle
                    className=" d-inline d-lg-none"
                    style={{
                    background: "transparent",
                    marginTop: 45,
                    marginLeft: 3,
                    border: 0,
                    }}
                >
                    <svg
                    className="bi bi-gear"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{
                        fontSize: 27,
                        background: "#fcf5e1",
                        borderRadius: 10,
                    }}
                    >
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    style={{
                    borderRadius: 0,
                    borderColor: "black",
                    borderWidth: "1px",
                    fontFamily: '"Hachi Maru Pop", serif',
                    background: "#fcf5e1",
                    padding: 0,
                    }}
                >
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={handleModalProfilePicShow}
                    >
                    Change profile pic
                    </Dropdown.Item>
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={handleModalBackgroundPicShow}
                    >
                    Change background pic
                    </Dropdown.Item>
                    <Dropdown.Item
                    id="drop-item"
                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 14,
                    }}
                    onClick={setPasswordModalShow}
                    >
                    Change Password
                    </Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            )}
            </div>
        </div>

        {/* ////////////////////////////////////////////// Num videos */}

        <div
            className="row d-flex flex-row justify-content-center align-items-center"
            style={{ height: "40%", width: "100%", marginTop: "-54px" }}
        >
            <div
            className="col d-flex flex-row justify-content-end"
            style={{ marginTop: 12 }}
            >
            <svg
                className="bi bi-camera-video-fill"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{ fontSize: 21, marginRight: 8 }}
            >
                <path
                fillRule="evenodd"
                d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z"
                />
            </svg>
            <small style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                {userData?.num_videos}
            </small>
            </div>

            {/* ////////////////////////////////////////////// Profile pic */}
            <div
            className="col-sm-2 d-flex flex-column justify-content-center align-items-center"
            id="profile-image"
            style={{
                paddingRight: 0,
                paddingLeft: 0,
                width: 120,
            }}
            >
            <img
                className="rounded-circle"
                width="120px"
                height="120px"
                style={{ margin: 0, width: 120, height: 120 }}
                src={userData?.profile_pic?.substring(
                0,
                userData.profile_pic.lastIndexOf("?")
                )}
                alt={userData?.username}
            />
            </div>

            {/* ////////////////////////////////////////////// Num Likes */}
            <div
            className="col d-flex flex-row justify-content-start"
            style={{ marginTop: 12 }}
            >
            <small style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                {userData?.num_likes}
                <svg
                className="bi bi-heart-fill"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{ marginLeft: 8, fontSize: 20, marginTop: 2 }}
                >
                <path
                    fillRule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                />
                </svg>
            </small>
            </div>
        </div>



        <div
        className="row d-flex flex-row justify-content-center"
        style={{ width: "100%"}}>

            <div
            className="col d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: 19}}>


                <div
                className="row"
                style={{
                marginBottom: "15px",
                fontFamily: '"Hachi Maru Pop", serif',
                fontWeight: "bold",}}>
                    {userData?.username}
                </div>

            <div className="column d-flex flex-row justify-content-center align-items-center"
            style={{width: "100%" }}>  


            {isFollowing && userId !== user?.id && (
                <button
                id="follow-btn-sm"
                className="btn btn-primary border rounded-0"
                type="button"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    background: "#4ed472",
                    color: "white",
                    marginRight: "9%",
                }}
                onClick={handleOnFollowBtnClick}
                >
                following
                </button>
            )}

            {!isFollowing && userId !== user?.id && (
                <button
                id="follow-btn-sm"
                className="btn btn-primary border rounded-0"
                type="button"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    color: "white",
                    background: "#1c80e4",
                    marginRight: "10%",
                }}
                onClick={handleOnFollowBtnClick}
                >
                follow
                </button>
            )}

            <div
            className="column d-flex flex-column justify-content-center align-items-center "
            // style={{ marginTop: "-15px"}}
            >

                <div className="row">
                <small
                    id="followers-badge-sm"
                    className="d-flex text-center border rounded flex-row justify-content-center align-items-center border rounded-0"
                    style={{
                    background: "rgba(33,37,41,0.51)",
                    fontFamily: '"Hachi Maru Pop", serif',
                    color: "rgb(255,255,255)",
                    width: "auto",
                    height: 38,
                    paddingRight: 12,
                    paddingLeft: 12,
                    }}
                >
                    {numFollowers}
                    <svg
                    className="bi bi-people"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{
                        marginTop: 2,
                        marginLeft: 16,
                        fontSize: 23 }}
                    >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                    </svg>
                </small>
                </div>
            </div>
            </div>


            </div>
        </div>


        <div
            className="row"
            style={{
            height: "10%",
            marginLeft: 0,
            marginRight: 0,
            width: "100%",
            marginTop: 8,
            }}
        >
            {/* ////////////////////////////////////////////// About */}
            <div
            className="col d-sm-flex justify-content-sm-center"
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                width: "100%",
                height: "100%",
            }}
            >
            <small
                id="about-text"
                className="text-break text-center d-flex justify-content-center align-items-start"
                style={{ height: "100%" }}
            >
                <br />
                {userData?.about}
                <br />
                <br />
            </small>
            </div>
        </div>

        {/* ////////////////////////////////////////////// Modal */}
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={handleModalClose}
            animation={false}
        >
            <div
            className="modal-dialog modal-dialog-centered border border-3 border-dark"
            role="html"
            style={{
                width: "100%",
                height: "100%",
                marginTop: 0,
                marginBottom: 0,
                padding: 0,
                margin: 0,
            }}
            >
            <div
                className="modal-content"
                style={{
                borderRadius: 0,
                background: "#fcf5e1",
                }}
            >
                <div className="d-flex flex-row justify-content-center">
                <div
                    className="col-11 col d-flex flex-column justify-content-center align-items-center"
                    style={{ marginTop: 10 }}
                >
                    <h5
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontWeight: "bold",
                    }}
                    >
                    {updatePic === "Profile"
                        ? "Upload Profile Image"
                        : "Upload Background Image"}
                    </h5>
                </div>
                <div className="col d-flex flex-row justify-content-end">
                    <button
                    onClick={handleModalClose}
                    style={{
                        background: "transparent",
                        border: "none",
                    }}
                    >
                    <svg
                        className="bi bi-x-lg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ marginRight: 8, fontSize: 30, marginTop: 10 }}
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    </button>
                </div>
                </div>
                <div className="modal-body d-flex flex-column justify-content-center align-items-stretch">
                <div className="inputWrapper">
                    <input
                    className="fileInput"
                    type="file"
                    name="file1"
                    disabled={disableFileInput}
                    onChange={(e) => {
                        updatePic === "Profile"
                        ? handleProfileImageChange(e)
                        : handleBackgroundImageChange(e);
                    }}
                    />
                    <i
                    className="la la-file-photo-o d-flex flex-grow-1 flex-fill justify-content-center align-items-center align-content-center"
                    style={{
                        fontSize: 110,
                        borderRadius: 20,
                        borderStyle: "none",
                        color: "white",
                    }}
                    size={70}
                    />
                </div>
                </div>
            </div>
            </div>
        </Modal>

        {/* ////////////////////////////////////////////// Video area */}
        <div
            className="col d-flex flex-column justify-content-start align-items-center"
            style={{
            marginTop: 8,
            height: "100%",
            paddingRight: 0,
            paddingLeft: 0,
            width: "100%",
            }}
        >
            {userData.id && (
            <VideoArea
                fetchAPI={
                process.env.REACT_APP_BASE_URL + `/user/videos/${userData.id}/`
                }
                numItemsInit={10}
                numItemsOnScroll={10}
                userVideoArea={true}
            />
            )}
        </div>

        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={errorModal}
            onHide={handleErrorModalClose}
            animation={false}
        >
            <div
            className="modal-dialog modal-dialog-centered"
            role="html"
            style={{
                width: "100%",
                height: "100%",
                marginTop: 0,
                marginBottom: 0,
            }}
            >
            <div className="modal-content">
                <div className="modal-header">
                <h4
                    className="modal-title"
                    style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                >
                    {errorModalTitle}
                </h4>
                <button
                    className="btn-close"
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    onClick={handleErrorModalClose}
                />
                </div>
                <div className="modal-body">
                <p style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                    verify your email to be able to {errorModalMessage}. we have
                    sent you a verification email. check your spam if you do not
                    see it in inbox.
                </p>
                </div>
                <div className="modal-footer">
                <button
                    className="btn btn-primary border rounded-0 border-0"
                    type="button"
                    style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                    onClick={handleErrorModalClose}
                >
                    close
                </button>
                </div>
            </div>
            </div>
        </Modal>

        <Modal
            size="md"
            centered
            show={passwordModalShow}
            onHide={handlePasswordModalClose}
            animation={false}
        >
            <div
            className="modal-dialog modal-dialog-centered border border-3 border-dark"
            role="html"
            style={{
                width: "100%",
                height: "100%",
                marginTop: 0,
                marginBottom: 0,
                padding: 0,
                margin: 0,
            }}
            >
            <div
                className="modal-content"
                style={{
                borderRadius: 0,
                background: "#fcf5e1",
                }}
            >
                {/* Modal header */}
                <div className="d-flex flex-row justify-content-center">
                <div
                    className="col-11 col d-flex flex-column justify-content-center align-items-center"
                    style={{ marginTop: 10 }}
                >
                    <h5
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        marginLeft: 40,
                        fontWeight: "bold",
                    }}
                    >
                    Change Password
                    </h5>
                </div>
                <div className="col d-flex flex-row justify-content-end">
                    <button
                    onClick={handlePasswordModalClose}
                    style={{
                        background: "transparent",
                        border: "none",
                    }}
                    >
                    <svg
                        className="bi bi-x-lg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ marginRight: 8, fontSize: 30, marginTop: 10 }}
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    </button>
                </div>
                </div>
                {/* Modal header /////////////////////////////////////////////////*/}

                <div className="modal-body d-flex flex-column justify-content-center align-items-center">
                <form
                    onSubmit={handleFormSubmit}
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ width: "100%" }}
                >
                    <input
                    className="form-control border rounded-0 has-validation"
                    type="password"
                    placeholder="Current Password"
                    style={{
                        textAlign: "center",
                        fontFamily: '"Hachi Maru Pop", serif',
                        marginTop: 10,
                    }}
                    onChange={(e) =>
                        setForm({ ...form, oldPassword: e.target.value })
                    }
                    required
                    />

                    {oldPasswordError && (
                    <ul>
                        {oldPasswordError.map((error) => {
                        return <li className="text-danger">{error}</li>;
                        })}
                    </ul>
                    )}

                    <input
                    className="form-control border rounded-0 has-validation"
                    type="password"
                    placeholder="New Password"
                    style={{
                        textAlign: "center",
                        fontFamily: '"Hachi Maru Pop", serif',
                        marginTop: 50,
                    }}
                    onChange={(e) =>
                        setForm({ ...form, newPassword: e.target.value })
                    }
                    required
                    />

                    <input
                    className="form-control border rounded-0 has-validation"
                    type="password"
                    placeholder="Repeat New Password"
                    style={{
                        textAlign: "center",
                        fontFamily: '"Hachi Maru Pop", serif',
                        marginTop: 10,
                    }}
                    onChange={(e) =>
                        setForm({ ...form, newPasswordConf: e.target.value })
                    }
                    required
                    />
                    {newPasswordError && (
                    <ul>
                        {newPasswordError.map((error) => {
                        return <li className="text-danger">{error}</li>;
                        })}
                    </ul>
                    )}
                    <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ width: "50%", marginTop: 40, marginBottom: 30 }}
                    >
                    Change
                    </button>
                </form>
                </div>
            </div>
            </div>
        </Modal>
        </div>
    </>
    );
    }

    export default UserProfile;
