import {  useState, useEffect  } from "react";
import axios from "axios";
import VideoStreamVideo from "./VideoStreamVideo";
import VideoStreamEnd from "./VideoStreamEnd";
import axiosService from "../helpers/axios";
import '../css/gradient.css'



function VideoStream(props){



    const { fetchAPI,
            numItemsInit,
            startIndex,
            loadedItems,
            require_login
        } = props;

    const [items, setItems] = useState([...loadedItems]);
    const [index, setIndex] = useState(startIndex);
    const [numIterate, setNumIterate] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchingMore, setFetchingMore] = useState(false);
    const [dataLimitReached, setDataLimitReached ] = useState(false)
    const [endReached, setEndReached ] = useState(false)



    useEffect(() => {
    if(items.length > 0){
        setIsLoading(false);
        return
    } 
    const getData = async () => {
        setIsLoading(true);
        if (require_login){
            axiosService.get(
                fetchAPI + `?limit=${numItemsInit}&offset=${startIndex}&page=1`)
                .then((response) => {
                    if (response.data.count === 0){setEndReached(true)}
                    setItems(response.data.results);
                    setIsLoading(false);
                    })
                .catch((err) => {console.log(err); setDataLimitReached(true);})
        }
        else{
            axios.get(
                fetchAPI + `?limit=${numItemsInit}&offset=${startIndex}&page=1`)
                .then((response) => {
                    if (response.data.count === 0){setEndReached(true)}
                    setItems(response.data.results);
                    setIsLoading(false);
                    })
                .catch((err) => {console.log(err); setDataLimitReached(true);})
        }
    };
    getData();
    }, []);



    const fetchMoreVideos = async () => {
        if (loadedItems.length !== 0){if (loadedItems.length < 10){setDataLimitReached(true); return}}
        
        setFetchingMore(true);
        const numItemsOnScroll = 10;


        if (require_login){
            try {
                const response = await axiosService.get(fetchAPI + `?limit=${numItemsOnScroll}&offset=${index}&page=1`);
                if (items.length >= response.data.count ) {setDataLimitReached(true)}
                setItems([...items, ...response.data.results]);
                }
            catch (error) {console.log(error); setDataLimitReached(true);}
            setFetchingMore(false);
        }
        else{
            try {
                const response = await axios.get(fetchAPI + `?limit=${numItemsOnScroll}&offset=${index}&page=1`);
                if (items.length >= response.data.count ) {setDataLimitReached(true)}
                setItems([...items, ...response.data.results]);
                }
            catch (error) {console.log(error); setDataLimitReached(true);}
            setFetchingMore(false);
        }
    };


    const hanldeOnWheel = (e) => {

            if(fetchingMore){return}
            if(e.deltaY < 0 ){
                if (endReached){setEndReached(false); return}
                if (index === 0){return}
                    
                setIndex((prevIndex) => prevIndex - 1)
                setNumIterate((preIterate) => preIterate + 1)}
            else if(e.deltaY > 0 ){
                if (index >= items.length - 1){setEndReached(true); return}
                setIndex((prevIndex) => prevIndex + 1)
                setNumIterate((preIterate) => preIterate + 1)}
            if (numIterate >= 2){setNumIterate(0)}
        }


    if(isLoading){return}
    if (((index + 4) > items.length) && !fetchingMore && !dataLimitReached){fetchMoreVideos();}

    console.log("index: ", index, "items.length: ", items.length)

    return(




                <div
                    id="videos-stream-container"
                    className="container-fluid p-0 m-0 d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "100vh",
                    overflow: "hidden",
                    // background: "pink",
                    overscrollBehavior: "none",
                    touchAction: "pan-down",
                }}>
                {(numIterate === 0  && !endReached) && <VideoStreamVideo
                video_srource= {process.env.REACT_APP_S3_BUCKET_URL + items[index]?.video_path_on_s3}
                username = {items[index]?.uploaded_by['username']}
                user_pic = {items[index]?.uploaded_by['profile_pic']}
                video_id = {items[index]?.id}
                num_views = {items[index]?.views}
                user_public_id = {items[index]?.uploaded_by['id']}
                tags = {items[index]?.tags}
                onMouseWheel={hanldeOnWheel}
                fetchingMore = {fetchingMore}
                />}
                {(numIterate === 1 && !endReached) && <VideoStreamVideo 
                video_srource={process.env.REACT_APP_S3_BUCKET_URL + items[index]?.video_path_on_s3}
                username = {items[index]?.uploaded_by['username']}
                user_pic = {items[index]?.uploaded_by['profile_pic']}
                video_id = {items[index]?.id}
                num_views = {items[index]?.views}
                user_public_id = {items[index]?.uploaded_by['id']}
                tags = {items[index]?.tags}
                onMouseWheel={hanldeOnWheel}
                fetchingMore = {fetchingMore}
                />}
                {(numIterate === 2 && !endReached)  && <VideoStreamVideo
                video_srource={process.env.REACT_APP_S3_BUCKET_URL + items[index]?.video_path_on_s3}
                username = {items[index]?.uploaded_by['username']}
                user_pic = {items[index]?.uploaded_by['profile_pic']}
                video_id = {items[index]?.id}
                num_views = {items[index]?.views}
                user_public_id = {items[index]?.uploaded_by['id']}
                tags = {items[index]?.tags}
                onMouseWheel={hanldeOnWheel}
                fetchingMore = {fetchingMore}
                />}
                {endReached && <VideoStreamEnd onMouseWheel={hanldeOnWheel} />}
                </div>


    )
};

export default VideoStream;