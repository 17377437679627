import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
function GoogleAuthLogin(){

    
    const login = useGoogleLogin({
        onSuccess: codeResponse => console.log(codeResponse),
        flow: 'auth-code',
      });

return(

        <button
        onClick={() => login()}
        
        >
            GOOGLE
        </button>
        


)
}

export default GoogleAuthLogin;



        {/* <GoogleLogin
        auto_select={true}
        onSuccess={credentialResponse => {
            console.log(credentialResponse);
        }}
        onError={() => {
            console.log('Login Failed');
        }}
        
        /> */}