import Offcanvasx from "../componenets/Offcanvas";
import Navbarx from "../componenets/Navbar";
import UsersArea from "../componenets/UsersArea";
import UsersAreaSearch from "../componenets/UsersAreaSearch";
import { useSelector } from "react-redux";



// function useMediaQuery(query) {
//     const [matches, setMatches] = useState(false);

//     useEffect(
//         () => {
//         const mediaQuery = window.matchMedia(query);
//         setMatches(mediaQuery.matches);
//         const handler = (event) => setMatches(event.matches);
//         mediaQuery.addEventListener("change", handler);
//         return () => mediaQuery.removeEventListener("change", handler);
//         },
//       [] // Empty array ensures effect is only run on mount and unmount
//     );
//     return matches;
// }


function Users(){

    const searchBarState = useSelector(state => state.search)
    // const widthHigherThanSM = useMediaQuery('(max-width: 576px)');



    return(
        <>
            <Navbarx withSearchBar={true}/>
            <div 
            className="container-fluid d-flex  flex-column  justify-content-start align-items-center"
            style={{
                marginTop: 115, 
            }}>

            {
                searchBarState.search === '' ?
                <UsersArea 
                fetchAPI={process.env.REACT_APP_BASE_URL + "/user/all"}
                numItemsInit={20}
                numItemsOnScroll={20}
                />
                :
                <UsersAreaSearch 
                fetchAPI={process.env.REACT_APP_BASE_URL + `/user/search/${searchBarState.search}/`}
                numItemsInit={20}
                numItemsOnScroll={20}
                />
            }


            <Offcanvasx />
            </div>
        </>
    )

};

export default Users;