import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";



function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


function VideoStreamEnd(props){

    const {
        onMouseWheel,
        explore,
        newest,
        liked,
        viewed,
    } = props;

    const navigate = useNavigate();


    const handleNavigateToExplore = () => {
        window.location.href = "/videos/explore/"
    };

    const handleNavigateToNewest = () => {
        window.location.reload();
        navigate("/videos/home/", {state: {"items": [], "index": 0, "sortbyState": "newest"}})
    };

    const handleNavigateToMostLiked = () => {
        window.location.reload();
        navigate("/videos/home/", {state: {"items": [], "index": 0, "sortbyState": "likes"}})
    };

    const handleNavigateToMostViewed = () => {
        window.location.reload();
        navigate("/videos/home/", {state: {"items": [], "index": 0, "sortbyState": "views"}})
    };

    const handleNavigateToHome = () => {
        navigate("/")
        window.location.reload();
    };


    const [sleepFor100ms, setSleepFor100ms] = useState(true);









    useEffect(() => {
        if (sleepFor100ms){return}
        const handleKeyPress = (event) => {
        if (event.repeat) return; 
    
        if (event.key === "ArrowDown"){
            onMouseWheel({"deltaY": 10})
        }
        else if (event.key === "ArrowUp"){
            onMouseWheel({"deltaY": -10})
        }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
        window.removeEventListener('keydown', handleKeyPress);
        };
    }, [sleepFor100ms]);








    sleep(1000).then(()=> {setSleepFor100ms(false)})
    if(sleepFor100ms){return}






















    return(
        <div
        className="container text-center d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
        onWheel={(e) => (onMouseWheel(e))}>
            <small
                style={{
                color: "rgb(255,255,255)",
                fontFamily: '"Hachi Maru Pop", serif',
                marginTop: "-33vh"
                }}
            >
                This videos stream
                <br />
                came to its end
            </small>
            <small
                style={{
                color: "rgb(255,255,255)",
                fontFamily: '"Hachi Maru Pop", serif',
                marginTop: 34
                }}
            >
                Check more content at
            </small>
            <div
                id="stream-end-line"
                className="hr"
                style={{ background: "#ffffff", marginTop: 17, height: 1 , width: "100%"}}
            />
            {explore &&
            <button
            style={{
            marginTop: 25,
            color: "rgb(255,255,255)",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "transparent",
            border: "0"
            }}
            onClick={handleNavigateToExplore}>
                Explore
            </button>
            }

            {newest &&
            <button
            style={{
            color: "rgb(255,255,255)",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "transparent",
            border: "0",
            marginTop: 25}}
            onClick={handleNavigateToNewest}>
                Recently uploaded
            </button>
            }


            {liked &&
            <button
            style={{
            color: "rgb(255,255,255)",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "transparent",
            border: "0",
            marginTop: 25}}
            onClick={handleNavigateToMostLiked}>
                Most liked
            </button>
            }


            {viewed &&
            <button
            style={{
            color: "rgb(255,255,255)",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "transparent",
            border: "0",
            marginTop: 25}}
            onClick={handleNavigateToMostViewed}>
                Most viewd
            </button>
            }

            <button
            style={{
            color: "rgb(255,255,255)",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "transparent",
            border: "0",
            marginTop: 25}}
            onClick={handleNavigateToHome}>
                Home
            </button>
        </div>
    )
}


VideoStreamEnd.defaultProps = {
    explore: true,
    newest: true,
    liked: true,
    viewed: true
  }


export default VideoStreamEnd