import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import HoverVideoPlayer from 'react-hover-video-player';
import { useNavigate } from "react-router-dom";
import { setSrotbytag} from '../actions';
import { useDispatch} from 'react-redux'


function    VideoCard(props){
    const {
        video_source,
        title,
        tags,
        user_name,
        user_pic,
        user_id,
        num_likes,
        num_views,
        items,
        index,
        sortbyState,
        tag
    } = props;



    const hoverTip = title + " | " + tags?.map((tag) => (' #' + tag)).join('')

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {hoverTip}
        </Tooltip>
        );



        const navigate = useNavigate();
        const dispatch = useDispatch();


        const handleOnCardClick = (e) => {

            if (e.target.classList.value){
                if (!user_id){return}
                window.location.href = `/user/${user_id}/`
            }
            else{
                sortbyState === "from_user_page" ? 
                navigate(`/videos/user/`,{state: {"items": items, "index": index, "user": user_id}}):
                navigate(`/videos/home/`,{state: {"items": items, "index": index, "sortbyState": sortbyState, "tag": tag}})
            }
        }


        // const handleNavigateToUserProfile = () => {
        //     if (!user_id){return}
        //     window.location.href = `/user/${user_id}/`
        // };
        // const handleOnTagClick = (tag) => {
        //     navigate(`/videos/home/`,{state: {"items": [], "index": 0,"sortbyState": "tag", "tag": tag}})
        // }

        const navigateToTag = (tag) => {
            window.location.href = `/tag/${tag}`
        }


    return(
            <div
            className="d-flex flex-column justify-content-start align-items-center"
            id="video-card"
            style={{
            height: "auto",
            width: "auto",
            padding: 0,
            paddingBottom: 0,
            marginRight:  40,
            marginBottom: 50}}>

                {/* Card /////////////////////////////////////////////////////*/}
                <div
                className="card"
                id = "card"

                style={{
                width: 218,
                marginTop: 0,
                height: 341,
                marginRight: 0,
                borderColor: "#fcf5e1",
                borderRadius: 20,
                paddingRight: 0}}>

                    {/* Card Body /////////////////////////////////////////////////////*/}
                    <div
                    className="card-body d-flex flex-column justify-content-start align-items-center shadow-lg"
                    id="video"
                    style={{
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    }}>

                        <div
                        className="row"
                        style={{ marginRight: 0, marginLeft: 0, width: "100%", height: "100%" }}>
                            <div
                            id = "overlay"
                            className="col"
                            style={{
                            width: "100%",
                            height: "100%",
                            paddingRight: 0,
                            paddingLeft: 0}}
                            >

                                {/* Video Player /////////////////////////////////////////////////////////////////////*/}
                                <HoverVideoPlayer
                                videoSrc = {video_source}
                                restartOnPaused={true}
                                muted={true}
                                sizingMode = "container"
                                style={{
                                background: "white",
                                objectFit: "fill",
                                height: "100%",
                                width: "100%",
                                cursor: "pointer"
                                }}
                                onClick={(e) => handleOnCardClick(e)}
                                playbackStartDelay={200}
                                hoverOverlay={
                                        <div
                                        id="video-overlay"
                                        className="column d-flex border border-3 border-dark"
                                        style={{
                                        position: "relative",
                                        // background: "rgba(255,255,255,0.68)",
                                        width: "100%",
                                        // height: "30%",
                                        marginRight: 0,
                                        marginLeft: 0,
                                        top: 0,
                                        // left: 0,
                                        // right: 0,
                                        // background: "linear-gradient(#fcf5e1 90%, rgba(255,255,255,0) 100%)"
                                        background: "white",
                                        opacity: "0.9"
                                    }}
                                    // onClick={handleNavigateToUserProfile}
                                    onClick={(e) => handleOnCardClick(e)}


                                        >
                                        <div
                                        className="col-xxl-4"
                                        style={{ marginLeft: 6, marginTop: 6, paddingRight: 0, paddingLeft: 0 }}>
                                            <img
                                            className="border rounded-circle"
                                            src={user_pic?.substring(0 , user_pic?.lastIndexOf("?"))}
                                            width={60}
                                            height={60}
                                            style={{cursor: "pointer"}}
                                            // onClick={handleNavigateToUserProfile}
                                            // onClick={handleNavigateToUserProfile}

                                            />
                                        </div>
                                        <div
                                        className="col d-flex flex-column justify-content-center align-items-end justify-content-xxl-center align-items-xxl-start"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}>
                                            <small
                                            className="text-truncate"
                                            style={{
                                            // textAlign: "center",
                                            fontFamily: '"Hachi Maru Pop", serif',
                                            fontWeight: "bold",
                                            maxWidth: 123,
                                            fontSize: 13,
                                            marginLeft: 13,
                                            cursor: "pointer"}}
                                            // onClick={c}
                                            // onClick={handleNavigateToUserProfile}

                                            >
                                                {user_name}
                                            </small>
                                        </div>
                                    </div>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Card bottom bar /////////////////////////////////////////////*/}
                <div
                className="row d-flex flex-row justify-content-center align-items-center"
                style={{ width: "100%", borderBottom: "1px solid rgba(0,0,0,0.55)" }}>
                    <div className="col-6 d-flex flex-row justify-content-start align-items-xxl-center">
                        <svg
                        className="bi bi-eye text-dark"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ fontSize: 21, marginRight: 5 }}>
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                        </svg>

                        <small style={{ fontFamily: '"Hachi Maru Pop", serif', fontSize: 12 }}>
                            {num_views}
                        </small>
                    </div>
                    <div className="col-6 d-flex flex-row justify-content-end align-items-xxl-center">
                    
                    <small
                    style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 12,
                    marginRight: 4,
                    textAlign: "center",
                    marginBottom: 3}}>
                        {num_likes}
                    </small>

                    <svg
                    className="bi bi-heart"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ fontSize: 19 }}>
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                    </svg>
                </div>
            </div>

            <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}>
                <small
                id="video-title"
                className="text-break text-start"
                style={{
                color: "#212529",
                width: 217,
                paddingTop: 0,
                wordWrap: "break-word",
                fontFamily: '"Hachi Maru Pop", serif',
                fontWeight: "bold",
                fontSize: 12,
                overflow: "hidden",
                maxWidth: 217,
                marginTop: 10,
                maxHeight: 37}}>
                    {title}
                </small>
            </OverlayTrigger>

            <div className="row" style={{ width: "100%", maxHeight: 55 }}>
                <div
                id="tags-column"
                className="col"
                style={{
                overflow: "hidden",
                height: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                width: 239,
                // background: "#faf2dc",
                marginTop: 6}}>

                {tags && tags.map((tag) => (
                    <small
                    id="small-tag"
                    className="text-break border-2"
                    style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontWeight: "bold",
                    paddingRight: 8,
                    paddingLeft: 8,
                    marginRight: 2,
                    fontSize: 11,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderBottomWidth: 2,
                    cursor: "pointer"}}


                    // onClick={()=> (sortbyState  === "from_user_page")?
                    //                 handleOnTagClick(tag):
                    //                 dispatch(setSrotbytag(tag))}
                    

                    onClick={()=> navigateToTag(tag)}

                                    >
                        {tag}
                    </small>
                ))}
                </div>
            </div>
        </div>
)};


export default VideoCard;


